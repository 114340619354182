@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);
html, body {
  margin: 0;
  padding: 0;
  background: #F8F8F8;
  background: hsl(49, 15%, 95%);
  min-height: 100vh;
}

body .sui-layout-body {
  background: transparent;
}


.sui-facet,
body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sui-layout-header {
  display: none;
}

.fullscreen {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background: black;
}
.fullscreen button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.fullscreen img {
  display: block;
  max-height: 100vh;
  max-width: 100%;
}

.sui-layout-body__inner {
  max-width: none!important;
}
.result img {
  max-width: 100%;
  height: auto;
}
.result {
  transition: transform .3s;
}
.result:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.grid-item {
  margin-bottom: 40px;
  overflow: hidden;
}
.grid-item,
.grid-sizer {
  width: 30%;
}

.gutter-sizer { width: 4%; }

.label-creator {
  color: hsl(49, 10%, 50%);
}
.artwork-label {
  font-weight: 600;
}

/*
@media screen and (min-width: 768px) {
  .grid-sizer {
    width: 20%;
  }
}
*/
.sui-search-box__text-input:focus {
  border: none!important;
  box-shadow: none!important;
}
body .sui-layout-body__inner {
  padding: 0;
}
body .sui-layout-main {
  padding: 32px 0 32px 32px;
}
.nav {
  display: none;
}
@media screen and (min-width: 800px) {
  .app > .loader-wrapper {
    margin: 3rem 0;
  }
  .app > .loader-wrapper,
  body .sui-layout-main {
    margin-left: 420px;
  }
  body .sui-layout-sidebar {
    width: 320px;
    left: 70px;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 2;
    padding: 0;
  }
  .nav {
    display: block;
    width: 70px;
  }
  .nav-title {
    height: 70px;
  }
}
@media screen and (min-width: 1500px) {
  body .sui-search-box {
    margin-right: 1.75rem;
  }
  body .facet-label,
  body .facet-wrapper.opened .facet-content {
    padding-right: 2rem;
  }
  .app > .loader-wrapper,
  body .sui-layout-main {
    margin-left: 530px;
  }
  body .sui-layout-sidebar {
    width: 430px;
    left: 100px;
  }
  .nav {
    width: 100px;
  }
  .nav-title {
    height: 100px;
  }
}
.side-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
}

.facet-label:hover {
  cursor: pointer;
}

.facet-label {
  padding: 1rem;
  padding-left: 0;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.facet-label svg {
  display: block;
}

.facet-wrapper {
  min-height: 56px;
  overflow: hidden;
}
.facet-wrapper.closed .facet-content {
  display: none;
}
.facet-wrapper.opened .facet-content {
  display: block;
  padding: 0 1rem 0 0;
  overflow: scroll;
  height: 100%;
  min-height: 100%;
}

.sui-multi-checkbox-facet {
}
.sui-facet {
  padding-bottom: 5rem!important;
}
.facet-wrapper.opened {
  /* flex-grow: 1; */
  flex-grow: 1;
}
.facet-closed.opened {
  height: auto;
}



body .sui-facet-search__text-input:focus {
  border: none;
}
body .sui-facet-search__text-input {
  background: rgba(0,0,0,0.06);
  background: hsl(49, 15%, 95%);
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  margin-bottom: .5rem;
}

body .sui-search-box__text-input {
  border: none;
  padding: 0;
  padding-left: .75rem;
  outline: none;
  font-size: 14px;
  width: 100%;
}
body .sui-search-box__submit:hover {
  background: black;
}

body .sui-search-box__submit {
  font-weight: bold;
  width: 30px;
  flex-shrink: 0;
  font-family: inherit;
  font-size: 14px;
  padding: 6px;
  margin: .2rem;
  /* margin-left: 10px; */
  text-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
  color: white;
  border: none;
  box-shadow: none;
  background: black;
  cursor: pointer;
  border-radius: 100%;
}

.sui-search-box {
  border-radius: 20rem;
  box-shadow: 0 5px 25px rgba(0,0,0,0.11);
  margin: 1rem;
  margin-left: 0;
  padding: .5rem .5rem .5rem 1rem;
}

.filter-badges {
  margin: .5rem 1rem 0;
  margin-left: 0;
}

.badge-clear:hover {
  cursor: pointer;
}

.badge-clear {
  font-size: 11px;
  line-height: 0;
  margin-left: 8px;
  position: relative;
  top: -1px;
}

.filter-badges .badge {
  background: hsl(49, 15%, 95%);
  border-radius: 3rem;
  padding: .2rem .7rem;
  display: inline-block;
  margin-right: .5rem;
  margin-bottom: 1rem;
}

#openseadragon {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  z-index: 500;
}
#openseadragon-wrapper {
  color: #fff;
}

.hidden {
  display: none;
}

.viewer-controls {
  z-index: 501;
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.viewer-controls div:hover {
  cursor: pointer;
}
/*
.viewer-controls div#zoom-in:hover {
  cursor: zoom-in;
}
.viewer-controls div#zoom-out:hover {
  cursor: zoom-out;
}
*/
.viewer-controls div:first-child {
  margin-top: 0;
}
.viewer-controls div {
  font-size: 1.5rem;
  color: #000;
  line-height: 0;
  margin-top: 1rem;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3rem;
  display: flex!important;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.sui-layout-body:after {
  background: transparent!important;
}


/**
 * Nav
 */
.nav {
  position: fixed;
  padding: 2rem 0;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  background: #fff;
  /* border-right: 1px solid #ddd; */
}

.nav-title:hover {
  cursor: pointer;
}
.nav-title {
  font-weight: bold;
  transform: translateX(-100%) rotate(270deg);
  margin-right: -100px;
  white-space: nowrap;
  position: absolute;
  text-align: left;
  transform-origin: top right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 1;
}







.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.map .spinner-wrapper {
  z-index: 10;
  position: relative;
}
.map .spinner-container,
.map-placeholder .spinner-container {
  background: rgba(0,0,0,0.8);
  color: #fff;
}
.spinner-container {
  color: #666;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #eee;
  padding: 10px 20px 10px 12px;
  border-radius: 100px;
}
.map-placeholder .spinner-border,
.map .spinner-border {
  border-color: #fff;
  border-right-color: transparent;
}
.spinner-border {
  margin-right: .75rem;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: -.125em;
  border: .15em solid #666;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}





/**
 * Loader.
 */

.sui-layout-main-body {
  position: relative;
}
.loader-wrapper.loader-content {
}
.loader-wrapper.loader-app {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  width: 60%;
}
@media screen and (min-width: 800px) {
  .loader-wrapper {
    margin: 4rem 0;
  }
}
.loader-wrapper {
  text-align: center;
  color: hsl(49, 5%, 50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.loader {
  position: relative;
  /* height: 3em; */
  margin: 1rem 0;
  width: 100%;
  display: flex;
  height: 12em;
  justify-content: center;
  align-items: center;
}

.loader div {
  display: inline-block;
  height: 10em;
  width: 10em;
  position: absolute;
  background: #bbb;
  background: hsl(49, 15%, 70%);
  /* background: #006ab4; */
  border-radius: 50%;
  /*animation: ripple-loader 1s ease-out infinite; */
  animation: pulse 1.6s ease-in-out infinite;
}

.loader div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes pulse {
  0% { transform: scale(0); opacity: 0.5;}
  100% { transform: scale(1); opacity: 0;}
}

